.accounting-layout__nav__wrapper {
  display: flex;
  position: relative;
}

.accounting-layout__nav__link--hover {
  z-index: 0;
  background: #f8f8fa;
  border-radius: 12px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  position: absolute;
}

.accounting-layout__nav__link-line {
  position: absolute;
  bottom: -24px;
  height: 2px;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

.accounting-layout__nav__link {
  text-decoration: none;
  color: #707888;
  border-radius: 12px;
  padding: var(--spacing-xs) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  transition: all 0.2 ease-out;
  color: var(--color-secondary);
  z-index: 1;
  position: relative;
}

.accounting-layout__nav__link.active {
  color: rgb(5, 19, 47);
}
