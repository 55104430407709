body .structure {
  --background-color: rgba(16, 24, 40, 0.01);
  --border-color: #eee;
  
  --color-accent: #47999E;
  --color-primary: #242220;
  --color-secondary: #848382;

  --text-color-primary: var(--color-primary);
  --text-color-secondary: var(--color-secondary);

  --font-family: "InterVariable", "Inter", sans-serif;
  --font-family-numeric: "InterVariable", "Inter", sans-serif;
  --font-weight-normal: 400;
  --font-weight-bold: 600;

  --spacing-4xs: 2px;
  --spacing-3xs: 4px;
  --spacing-2xs: 6px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 40px;
  --spacing-5xl: 52px;
}

