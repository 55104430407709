.accounting-layout .accounting-layout__nav {
  display: flex;
  gap: var(--spacing-md);
  width: 100%;
}

.accounting-layout .accounting-layout__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:76px;
  border-bottom: 1px solid #EBEBEB;
  background: #fff;
  padding-left: 24px;
}

.accounting-layout__topbar__right {
  padding-left: var(--spacing-lg);
}

.accounting-layout__topbar__right button {
  outline: none;
  text-decoration: none;
  color: #848382;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #EEE;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  display: none;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: 11px;
  line-height: 14px;
  transition: all 150ms ease-out;
  cursor: pointer;
}

.accounting-layout__topbar__right button:hover {
  border-color: var(--color-accent);
  background-color: var(--color-accent-light);
}

@media (max-width: 992px) {
  .accounting-layout__topbar__right button {
    display: flex;
  }
}

.accounting-layout .accounting-layout__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.accounting-layout .Layer__view .Layer__view-header {
  background-color: #fff;
}